import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { EMPTY, forkJoin, from, Observable, of, throwError, timer } from 'rxjs';
import { catchError, delay, finalize, map, mergeMap, take, tap } from 'rxjs/operators';

import { SECONDS_180, SECONDS_60 } from '@app/shared/constants';
import { ErrorCodes } from '@app/shared/enums';
import { Environment, IAction, isErrorModel } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { isArray, isFunction, isHttpError, parseJwt } from '@app/shared/util';
import { ERROR_RATE_LIMIT, ERROR_SCRIPT_LOAD_FAIL } from '@fingerprintjs/fingerprintjs-pro';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Actions, createSelector, NgxsOnInit, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthActions, CoreActions, InsightActions, NotificationActions } from '../actions';
import {
    BrowserCheckDialogComponent,
    DenyBrowserDialogComponent,
    DenyIncognitoDialogComponent,
    DenyiOSDialogComponent,
    DenyRequestDesktopDialogComponent,
    RedirectMigrationDialogComponent,
} from '../dialogs';
import { CurrentUser, TokenResult, VisitorRequestModel } from '../models';
import { AuthService } from '../services';

export interface StateModel {
    browserCheckRetry: number;
    isReady: boolean;
    isAuthenticated: boolean;
    migrationId: string | null;
    fpjsToken: string | null;
    deviceId: string | null;
    returnUrl: string | null;
    token: TokenResult | null;
    currentUser: CurrentUser | null;
    thumbprint: string | null;
    requestId: string | null;
    fpjsResult: VisitorRequestModel | null;
    cookieTestResult: boolean | null;
    diagnosticResult: string | null;
    supportLink: string | null;
    serviceMessage: string | null;
    isMigrationRedirectEnabled: boolean | null;
    isPending: boolean;
    browserStatus: string | null;
    sessionExpiration: Date | null;
    errors: any;
}

@State<StateModel>({
    name: 'auth',
    defaults: {
        browserCheckRetry: 0,
        isReady: false,
        isAuthenticated: false,
        isMigrationRedirectEnabled: false,
        migrationId: null,
        fpjsToken: null,
        deviceId: null,
        returnUrl: null,
        token: null,
        currentUser: null,
        thumbprint: null,
        requestId: null,
        fpjsResult: null,
        cookieTestResult: null,
        diagnosticResult: null,
        supportLink: null,
        serviceMessage: null,
        isPending: false,
        browserStatus: null,
        sessionExpiration: null,
        errors: null,
    },
})
@Injectable({
    providedIn: 'root',
})
export class AuthState implements NgxsOnInit {
    static getBrowserStatus() {
        return createSelector([AuthState], (state: StateModel) => state.browserStatus);
    }
    static getBrowserRetry() {
        return createSelector([AuthState], (state: StateModel) => state.browserCheckRetry);
    }

    static isReady() {
        return createSelector([AuthState], (state: StateModel) => state.isReady);
    }

    static isPending() {
        return createSelector([AuthState], (state: StateModel) => state.isPending);
    }

    static isAuthenticated() {
        return createSelector([AuthState], (state: StateModel) => {
            return state.isAuthenticated;
        });
    }

    static getErrors() {
        return createSelector([AuthState], (state: StateModel) => state.errors);
    }

    static getReturnUrl() {
        return createSelector([AuthState], (state: StateModel) => {
            return state?.returnUrl;
        });
    }

    static getToken() {
        return createSelector([AuthState], (state: StateModel) => state.token);
    }

    static getCurrentUser() {
        return createSelector([AuthState], (state: StateModel) => state.currentUser);
    }

    static getThumbprint() {
        return createSelector([AuthState], (state: StateModel) => state.thumbprint);
    }

    static getMigrationId() {
        return createSelector([AuthState], (state: StateModel) => state.migrationId);
    }

    static getFpjsToken() {
        return createSelector([AuthState], (state: StateModel) => state.fpjsToken);
    }

    static getDiagnosticResult() {
        return createSelector([AuthState], (state: StateModel) => state.diagnosticResult);
    }

    static getRequestId() {
        return createSelector([AuthState], (model: StateModel) => model.requestId);
    }

    static getCookieTestResult() {
        return createSelector([AuthState], (state: StateModel) => state.cookieTestResult);
    }

    static getSupportLink() {
        return createSelector([AuthState], (state: StateModel) => state.supportLink);
    }

    static getLinkId() {
        return createSelector(
            [AuthState.getClaimValue('http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier')],
            (value: string) => value,
        );
    }

    static getVisitorId() {
        return createSelector([AuthState.getClaimValue('vid')], (value: string) => value);
    }

    static getClaimValue(type: string) {
        return createSelector([AuthState.getToken()], (token: TokenResult | null) => {
            if (token) {
                const jwt = parseJwt(token.token);
                const value = jwt[type];

                return value || '';
            }

            return null;
        });
    }

    static getSessionExpiration() {
        return createSelector([AuthState], (state: StateModel) => state.sessionExpiration);
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private store: Store,
        private actions: Actions,
        private deviceService: DeviceDetectorService,
        private authService: AuthService,
        private matDialog: MatDialog,
        private fpjs: FingerprintjsProAngularService,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngxsOnInit({ dispatch, patchState, getState }: StateContext<StateModel>) {
        const url = new URL(window.location.href);
        const migrationId = url.searchParams.get('mid') || null;

        patchState({ browserStatus: 'Initializing', migrationId });

        if (migrationId) {
            dispatch(
                new Navigate(
                    [window.location.pathname],
                    { mid: null },
                    { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' },
                ),
            );
        }

        const dialog = this.matDialog.open(BrowserCheckDialogComponent, {
            id: 'browser-check',
            disableClose: true,
            closeOnNavigation: false,
        });


        this.authService
            .getServerInfo()
            .pipe(
                tap(
                    ({
                        fpjsToken,
                        supportLink,
                        deviceId,
                        isMigrationRedirectEnabled,
                        privateContainerName,
                        publicContainerName,
                    }) => {
                        this.env.publicContainerName = publicContainerName;
                        this.env.privateContainerName = privateContainerName;
                        patchState({ fpjsToken, supportLink, deviceId, isMigrationRedirectEnabled });
                    },
                ),
                mergeMap(({ serviceMessage }) => {
                    if (serviceMessage) {
                        dispatch(new NotificationActions.Acknowledge(serviceMessage, false));
                        return EMPTY;
                    }

                    return forkJoin({
                        dispatch: dispatch([new AuthActions.ComputeThumbprint(), new AuthActions.CookieTest()]).pipe(
                            take(1),
                        ),
                        token: this.authService.getCurrentToken().pipe(
                            catchError(err => {
                                if (isErrorModel(err)) {
                                    if (err.errorCode === ErrorCodes.InvalidVersion) {
                                        dispatch(new AuthActions.Logout());

                                        return throwError(() => err);
                                    }
                                }

                                return of(null);
                            }),
                        ),
                        cookieTest: this.actions.pipe(
                            ofActionDispatched(AuthActions.CookieTestSuccess, AuthActions.CookieTestFailure),
                            take(1),
                            map(action => action instanceof AuthActions.CookieTestSuccess),
                        ),
                    }).pipe(
                        mergeMap(({ cookieTest, token }) => {
                            if (!cookieTest) {
                                console.log('Cookie test failed');
                                patchState({ cookieTestResult: false });

                                dispatch(new AuthActions.DenyIncognitoEntry({ cookieTest: true }));

                                return EMPTY;
                            }

                            patchState({ cookieTestResult: true });

                            const { isMigrationRedirectEnabled } = getState();

                            if (isMigrationRedirectEnabled || window.location.search.includes('migrate')) {
                                dispatch(new AuthActions.ShowMigrationRedirect());
                                return EMPTY;
                            }

                            if (token) {
                                const jwt = parseJwt(token.token);
                                const vid = jwt.vid;

                                dispatch([
                                    new AuthActions.SetAuthToken(token),
                                    new InsightActions.SetAuthenticationContext(vid),
                                ]);
                            }

                            return of(null);
                        }),
                    );
                }),
                take(1),
                catchError(error => {
                    console.error(error);
                    // Failed to load the JS script of the agent

                    return EMPTY; // needs to change to show an error modal to the user. FPJS service outage stopped the app from loading
                }),
                tap(() => {
                    patchState({ isReady: true });
                }),
                finalize(() => dialog.close()),
            )
            .subscribe();
    }

    @Action(AuthActions.UserAuthenticated)
    userAuthenticated(ctx: StateContext<StateModel>) {
        ctx.patchState({ isAuthenticated: true });
    }

    @Action(AuthActions.NotAuthenticated)
    notAuthenticated(ctx: StateContext<StateModel>) {
        ctx.patchState({ isAuthenticated: false });
    }

    @Action(AuthActions.UpdateReturnUrl)
    updateReturnUrl(ctx: StateContext<StateModel>, { returnUrl }: AuthActions.UpdateReturnUrl) {
        ctx.patchState({ returnUrl });
    }

    @Action(AuthActions.SetAuthToken)
    setAuthToken({ patchState }: StateContext<StateModel>, { token }: AuthActions.SetAuthToken) {
        patchState({ token });
    }

    @Action(AuthActions.UpdateCookie)
    updateCookie() {
        return this.authService.updateCookie();
    }

    @Action(AuthActions.LoadClientInfo)
    loadClientInfo({ patchState }: StateContext<StateModel>) {
        return this.authService.getServerInfo().pipe(
            tap(({ fpjsToken, supportLink }) => {
                patchState({ fpjsToken, supportLink });
            }),
        );
    }

    @Action(AuthActions.CookieTest)
    startCookieTest(ctx: StateContext<StateModel>) {
        return this.authService.cookieTest().pipe(
            delay(1000),
            mergeMap(id => this.authService.cookieTest(id)),
            mergeMap(() => this.store.dispatch(new AuthActions.CookieTestSuccess())),
            catchError(error => this.store.dispatch(new AuthActions.CookieTestFailure(error))),
        );
    }

    @Action(AuthActions.LoadUser)
    loadUser({ dispatch, patchState }: StateContext<StateModel>, action: AuthActions.LoadUser) {
        dispatch(new AuthActions.ClearErrors([AuthActions.LoadUserFailure]));

        return this.authService.getCurrentUser().pipe(
            mergeMap(user => {
                patchState({ currentUser: user });

                return dispatch(new AuthActions.LoadUserSuccess(user));
            }),
            catchError(error => dispatch(new AuthActions.LoadUserFailure(error))),
        );
    }

    @Action(AuthActions.ResetLoginState)
    resetLoginState({ patchState, dispatch }: StateContext<StateModel>) {
        dispatch(new AuthActions.ClearErrors([AuthActions.LoginFailure]));
        patchState({ isPending: false });
    }

    @Action(AuthActions.Login)
    login({ patchState }: StateContext<StateModel>) {
        patchState({ isPending: true });
    }

    @Action(AuthActions.LoginSuccess)
    loginSuccess({ dispatch, patchState }: StateContext<StateModel>) {
        patchState({ isPending: false });
        dispatch([new AuthActions.UserAuthenticated(), new AuthActions.UpdateCookie()]);
    }

    @Action(AuthActions.Logout)
    logout({ getState, setState, dispatch }: StateContext<StateModel>, { loginUrl, state }: AuthActions.Logout) {
        const {
            fpjsToken,
            thumbprint,
            requestId,
            fpjsResult,
            isReady,
            cookieTestResult,
            supportLink,
            isMigrationRedirectEnabled,
            serviceMessage,
            deviceId,
        } = getState();
        setState({
            isReady,
            fpjsToken,
            deviceId,
            thumbprint,
            requestId,
            fpjsResult,
            cookieTestResult,
            supportLink,
            isMigrationRedirectEnabled,
            serviceMessage,
            migrationId: null,
            diagnosticResult: null,
            errors: null,
            currentUser: null,
            isAuthenticated: false,
            isPending: false,
            token: null,
            returnUrl: null,
            browserStatus: null,
            browserCheckRetry: 0,
            sessionExpiration: null,
        });

        return this.authService.logout().pipe(finalize(() => dispatch(new AuthActions.LogoutRedirect(loginUrl, state))));
    }

    @Action(AuthActions.StartHeartBeat)
    startHeartBeat({dispatch}: StateContext<StateModel>, {interval}: AuthActions.StartHeartBeat): Observable<void> {

        return timer(interval).pipe(
            take(1),
            mergeMap(() => this.authService.heartBeat()),
            tap(() => {
                const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated());

                if (!isAuthenticated) {
                    throw new Error('Not authenticated');
                }

                dispatch(new AuthActions.StartHeartBeat(SECONDS_60)) // recursive call on a new thread
            }),
            catchError(err => this.store.dispatch(new AuthActions.Logout())),
        );
    }

    @Action(AuthActions.ComputeThumbprint)
    computeThumbprint(
        { dispatch, getState, patchState }: StateContext<StateModel>,
        { retry }: AuthActions.ComputeThumbprint,
    ) {
        const { thumbprint, deviceId } = getState();
        const delayInMs = retry > 0 ? Math.pow(2, retry + Math.random()) * 1000 : 0;

        if (thumbprint) {
            return;
        }

        if (delayInMs > 0) {
            patchState({
                browserCheckRetry: delayInMs,
            });
        }

        return of(null).pipe(
            delay(delayInMs),
            // mergeMap(() => FP.load({ token, endpoint: this.env.fpEndpoint })),
            // mergeMap(fp => fp.get()),
            mergeMap(() =>
                from(
                    this.fpjs.getVisitorData({
                        extendedResult: true,
                        tag: {
                            deviceId,
                        },
                    }),
                ),
            ),
            mergeMap(response => {
                patchState({
                    thumbprint: response.visitorId,
                    requestId: response.requestId,
                });

                return dispatch(new AuthActions.VerifyRequest(response.visitorId, response.requestId));
            }),
            catchError((error: any) => {
                if (error.message === ERROR_RATE_LIMIT) {
                    console.log('Rate limit error, retrying');
                    return dispatch(new AuthActions.ComputeThumbprint(retry + 1));
                }

                if (error.message === ERROR_SCRIPT_LOAD_FAIL) {
                    dispatch(
                        new AuthActions.DenyIncognitoEntry({
                            adblocker: navigator.onLine,
                        }),
                    );
                }

                return throwError(() => error);
            }),
        );
    }

    @Action(AuthActions.VerifyRequest)
    verifyRequest(
        { dispatch, patchState }: StateContext<StateModel>,
        { visitorId, requestId }: AuthActions.VerifyRequest,
    ): Observable<void> | void {
        return this.authService.verifyRequest(visitorId, requestId).pipe(
            mergeMap((fpjsResult: VisitorRequestModel) => {
                patchState({ fpjsResult });

                // {error: 'too many requests'}
                if ((fpjsResult as any).error && (fpjsResult as any).error === 'too many requests') {
                    return throwError(() => new Error(ERROR_RATE_LIMIT));
                }

                if (fpjsResult.visits.length > 0 && fpjsResult.visits[0].incognito === true) {
                    // grab location data
                    console.log('User is in incognito');
                    // patchState({ cookieTestResult: false });

                    // return dispatch(new AuthActions.DenyIncognitoEntry({ incognito: true }));
                }

                patchState({ cookieTestResult: false });
                return dispatch(new AuthActions.VerifyRequestSuccess());
            }),
        );
    }

    @Action(AuthActions.DenyiOSEntry)
    denyiOSEntry() {
        this.matDialog.closeAll();

        return this.matDialog
            .open(DenyiOSDialogComponent, {
                disableClose: true,
                closeOnNavigation: false,
            })
            .afterClosed();
    }

    @Action(AuthActions.DenyBrowserEntry)
    denyBrowserEntry(ctx: StateContext<StateModel>, { browser }: AuthActions.DenyBrowserEntry) {
        this.matDialog.closeAll();

        return this.matDialog
            .open(DenyBrowserDialogComponent, {
                disableClose: true,
                closeOnNavigation: false,
                data: browser,
            })
            .afterClosed();
    }

    @Action(AuthActions.DenyRequestDesktopEntry)
    denyRequestDesktopEntry() {
        this.matDialog.closeAll();

        return this.matDialog
            .open(DenyRequestDesktopDialogComponent, {
                disableClose: true,
                closeOnNavigation: false,
            })
            .afterClosed();
    }

    @Action(AuthActions.DenyIncognitoEntry)
    denyIncognitoEntry(ctx: StateContext<StateModel>, action: AuthActions.DenyIncognitoEntry) {
        this.matDialog.closeAll();

        return this.matDialog
            .open(DenyIncognitoDialogComponent, {
                disableClose: true,
                closeOnNavigation: false,
                maxWidth: '96vw',
                data: action.result,
            })
            .afterClosed();
    }

    @Action(AuthActions.ShowMigrationRedirect)
    showMigrationRedirect({ getState }: StateContext<StateModel>) {
        this.matDialog.closeAll();
        const { thumbprint } = getState();

        this.matDialog.open(RedirectMigrationDialogComponent, {
            disableClose: true,
            closeOnNavigation: false,
            maxWidth: '96vw',
            data: { thumbprint },
        });
    }

    @Action([AuthActions.RefreshToken])
    refreshToken({ dispatch }: StateContext<StateModel>) {
        return this.authService.refreshToken().pipe(mergeMap(token => dispatch([new AuthActions.SetAuthToken(token)])));
    }

    @Action([AuthActions.ActivateSession, CoreActions.SessionActive])
    activateSession({ dispatch }: StateContext<StateModel>, action: AuthActions.ActivateSession) {
        const trackLogin = action.trackLogin || action instanceof CoreActions.SessionActive;

        return this.authService.activateSession(trackLogin).pipe(
            mergeMap(token => dispatch([new AuthActions.SetAuthToken(token), new AuthActions.ActivateSessionSuccess()])),
            catchError(error => dispatch(new AuthActions.ActivateSessionFailure(error))),
        );
    }

    @Action(AuthActions.ActivateSessionSuccess)
    activateSessionSuccess({ dispatch, patchState }: StateContext<StateModel>) {
        const token = this.store.selectSnapshot(AuthState.getToken()) as TokenResult;
        const jwt = parseJwt(token.token);
        const sessionExpiration = jwt.sessExp ? new Date(jwt.sessExp) : null;

        patchState({ sessionExpiration });

        return dispatch([new AuthActions.UpdateCookie(), new AuthActions.LoginSuccess(), new AuthActions.StartHeartBeat(Math.random() * SECONDS_180)]);
    }

    @Action(AuthActions.SendDiagnosticData)
    sendDiagnosticData({ dispatch, patchState }: StateContext<StateModel>) {
        dispatch(new AuthActions.CookieTest());

        return forkJoin({
            cookieTest: this.actions.pipe(
                ofActionDispatched(AuthActions.CookieTestSuccess, AuthActions.CookieTestFailure),
                take(1),
                map(action => {
                    return action instanceof AuthActions.CookieTestSuccess;
                }),
            ),
            verifyRequest: of(null).pipe(
                // mergeMap(() => FP.load({ token, endpoint: this.env.fpEndpoint })),
                // mergeMap(fp => fp.get({ debug: true })),
                mergeMap(() => from(this.fpjs.getVisitorData())),
                mergeMap(response =>
                    this.authService
                        .verifyRequest(response.visitorId, response.requestId)
                        .pipe(map(result => [response, result])),
                ),
                catchError((error: any) => of([`FPJS Error: ${error.message}`])),
            ),
        }).pipe(
            mergeMap(result =>
                this.authService.sendDiagnosticData([
                    `Cookie test result: ${result.cookieTest}`,
                    `Browser Date: ${new Date()}`,
                    ...result.verifyRequest,
                ]),
            ),
            tap(result => patchState({ diagnosticResult: result })),
        );
    }

    @Action([
        AuthActions.LoginFailure,
        AuthActions.LoadUserFailure,
        AuthActions.UpdateCookieFailure,
        AuthActions.ActivateSessionFailure,
    ])
    handleFailures({ patchState, dispatch }: StateContext<StateModel>, action: { error: any }): Observable<void> | void {
        const error = action.error;
        patchState({ isPending: false });

        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof AuthActions.ActivateSessionFailure:
                if (isErrorModel(error) && isHttpError(error.source)) {
                    if (error.source.status === 401) {
                        dispatch(new AuthActions.Logout());
                        return;
                    }
                }
                break;
            case action instanceof AuthActions.LoginFailure:
                return this.store.dispatch(new AuthActions.AddErrors(AuthActions.LoginFailure, action.error));
            default:
                return this.store.dispatch([new NotificationActions.Error(`Unexpected error occurred`, error)]);
        }
    }

    @Action(AuthActions.ClearErrors)
    clearErrors(ctx: StateContext<StateModel>, { actions }: AuthActions.ClearErrors) {
        let errors = { ...this.store.selectSnapshot(AuthState.getErrors()) };

        if (isArray<IAction>(actions)) {
            actions.forEach(act => {
                errors[act.type] = null;
            });
        } else if (isFunction(actions)) {
            errors[actions.type] = null;
        } else {
            errors = {};
        }

        ctx.patchState({ errors });
    }

    @Action(AuthActions.AddErrors)
    addErrors(ctx: StateContext<StateModel>, { action, error }: AuthActions.AddErrors) {
        const errors = { ...this.store.selectSnapshot(AuthState.getErrors()) };

        errors[action.type] = error;

        ctx.patchState({ errors });
    }
}
